<template>
  <div class="customers-list">
    <div class="customers-list__item" v-for="(item, index) in data" :key="index">
      <div class="d-flex align-items-center">
        <div class="customers-list__item--img">
          <img :src="item.icon_path" width="24" height="24" alt="">
        </div>
        <router-link :to="`/bank/customers/customer/${item.id}`" class="customers-list__item--name">
          {{ item.name }}
        </router-link>
      </div>

      <div class="customers-list__item--values" v-if="!hideScore">
        <div class="customer-value">{{ numberDecimalsFormat(item.score) }}</div>
<!--        <div class="customer-percentage" :class="{'green-type': item.success}">-->
<!--          {{ item.percentage }}%-->
<!--          <inline-svg src="/media/buying/icons/percentage-icon.svg" />-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default {
  name: "CustomersList",
  props: {
    data: {
      type: Array
    },
    hideScore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      numberDecimalsFormat
    };
  }
};
</script>

<style scoped lang="scss">
.customers-list {
  height: 100%;
  max-height: 780px;
  overflow-y: auto;

  .customers-list__item--name {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 100px;
    background: #D9D9D9;
    border: 4px solid #fff;
  }

  &__item {
    padding: 18px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &:last-child {
      border-bottom: none;
    }

    &--img {
      margin-right: 17px;

      img {
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
      }
    }

    &--name {
      font-weight: 500;
      font-size: 16px;
      line-height: 158.02%;
      color: #2B2B2B;

    }

    &--values {
      display: flex;
      align-items: center;

      .customer-value {
        font-weight: 600;
        font-size: 16px;
        line-height: 158.02%;
        color: #2B2B2B;
        margin-right: 24px;
      }

      .customer-percentage {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #CE2419;

        svg {
          fill: #E22D21;
          transform: rotate(180deg);
        }

        &.green-type {
          color: #1B9F72;

          svg {
            fill: #2BC490;
            transform: rotate(0);
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    &__item {
      padding: 14px 15px;
    }
  }
}
</style>
